import globalReducer from '../../state/global/reducer'
import meReducer from '../../state/me/reducer'
import { AnyAction, AppState } from '../../types'

export const initialState: AppState = {
  global: {
    loading: false,
    pageClass: '',
    navOpen: false
  },
  me: { email: '', id: -1 }
}

const reducer = (state = initialState, action: AnyAction): AppState => {
  return {
    ...state,
    me: meReducer(state.me, action),
    global: globalReducer(state.global, action)
  }
}

export default reducer
