import classNames from 'classnames'
import { VictoryLabel, VictoryPie } from 'victory'

export type Data = {
  x: string
  y: number
}
interface Props {
  categoryPercentageOfTotalAssets: number
  className?: string
  data: Data[]
}

const DonutGraph: React.FC<Props> = ({ categoryPercentageOfTotalAssets, className, data }) => {
  const percentage = categoryPercentageOfTotalAssets ? `${categoryPercentageOfTotalAssets}%` : ''

  return (
    <div className="DonutGraph-Wrapper">
      <svg className={classNames('DonutGraph', className)} height="400" role="img" viewBox="15 0 400 400" width="400">
        <VictoryPie
          colorScale={['#1ECAD3', '#00567d']}
          data={data}
          height={430}
          innerRadius={116}
          labelComponent={
            <VictoryLabel
              backgroundPadding={[0, { left: 6 }]}
              className="DonutGraph-Label"
              inline
              style={[
                { fill: '#00567D', fontSize: 24, fontWeight: 700 },
                { fill: '#000', fontSize: 17, letterSpacing: 0.15 }
              ]}
              x={226}
              dy={-195}
              textAnchor="start"
            />
          }
          labelRadius={() => -12}
          labels={() => [`${percentage}`, 'Total Assets']}
          padAngle={0.6}
          standalone={false}
          width={430}
        />
        <VictoryLabel
          className="DonutGraph-Label DonutGraph-Label__Center"
          lineHeight={2.2}
          text={'Asset\n Allocation'}
          textAnchor="middle"
          x={215}
          y={216}
        />
        <line x1="224" y1="78" x2="240" y2="38" stroke="black" />
      </svg>
    </div>
  )
}

export default DonutGraph
