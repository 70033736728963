import * as React from 'react'
import { NavigateFunction } from 'react-router'

import { createAccount } from '../../api/createAccount'
import { PageUrls } from '../../components/Router/routes'
import { FormValues } from '../../forms/AccountForm'
import { AccountCategory } from '../../types'
import { Currency } from '../../utilities/Currency'

export const addAccount = async (
  values: FormValues,
  categoryIdParam: number,
  categories: AccountCategory[],
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
  navigate: NavigateFunction
) => {
  try {
    const category = categories.find((cat) => cat.id === +values.account_category_id)

    if (!category) {
      throw new Error('could not find category')
    }

    const newAccount = await createAccount(categoryIdParam, {
      ...values,
      balance_in_cents: `${Currency.dollarsToCents(parseFloat(values.balance_in_cents))}`
    })

    if (newAccount) {
      navigate(PageUrls.accountCategory(category.id))
    }
  } catch ({ message }) {
    setError(message as string)
  }
}
