import { useAppContext } from '../../contexts/AppContext'
import { icons } from '../../utilities/icons'
import BigList from '../BigList'
import BigRow from '../BigRow'

const WealthManagerInfo: React.FC = () => {
  const [appState] = useAppContext()

  if (!appState.me.wealth_manager) {
    return <></>
  }

  const actionComponent = (
    <div className="BigRow__action">
      <a href={`mailto:${appState.me.wealth_manager.email}`} title="Contact Wealth Manager">
        <icons.envelope title="Contact Wealth Manager" />
      </a>
    </div>
  )

  return (
    <BigList className="BigList__WealthManagerInfo">
      <BigRow
        title="Wealth Manager"
        description={appState.me.wealth_manager.full_name}
        actionComponent={actionComponent}
      />
    </BigList>
  )
}

export default WealthManagerInfo
