import useSWR from 'swr'

import AccountCategoryList from '../../components/AccountCategoryList'
import ContentHeader from '../../components/ContentHeader'
import PageContent from '../../components/PageContent'
import { PageNames, PageUrls } from '../../components/Router/routes'
import withAuthentication from '../../hoc/withAuthentication'
import { AccountCategory } from '../../types'
import endpoints from '../../utilities/endpoints'

const AccountCategories: React.FC = () => {
  const { data: accountCategoriesResponse } = useSWR<AccountCategory[]>(endpoints.accountCategories)
  const accountCategories = accountCategoriesResponse || []

  return (
    <>
      <ContentHeader headingOne="Kitchen Home" add={PageUrls.addAccountInterstitial()} addText={PageNames.addAccount} />
      <PageContent>
        <AccountCategoryList accountCategories={accountCategories} />
      </PageContent>
    </>
  )
}

export default withAuthentication(AccountCategories)
