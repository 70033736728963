import classNames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from '../../assets/img/arrow.svg'

const BackButton: React.FC<LinkProps> = ({ className, to, ...props }) => {
  return (
    <Link to={to} className={classNames('BackButton', className)} {...props}>
      <ArrowIcon />
    </Link>
  )
}

export default BackButton
