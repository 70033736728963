import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ContentHeader from '../../components/ContentHeader'
import { PageNames, PageUrls } from '../../components/Router/routes'
import { useAppContext } from '../../contexts/AppContext'
import AccountForm, { FormValues } from '../../forms/AccountForm'
import { AccountCategory } from '../../types'
import * as helpers from './helpers'

interface Props {
  accountCategories: AccountCategory[]
  initialFormValues: FormValues
}

const NewAccountFormWrapper: React.FC<Props> = ({ accountCategories, initialFormValues }) => {
  const [appState] = useAppContext()
  const [formDrawerOpen, setFormDrawerOpen] = useState<boolean>(false)
  const params = useParams<string>()
  const navigate = useNavigate()

  const categoryIdParam = parseInt(params.account_category_id!)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    setFormDrawerOpen(true)
  }, [])

  return (
    <div
      className={classNames('NewAccountFormWrapper', 'AccountFormWrapper', {
        AccountFormWrapper__drawerOpen: formDrawerOpen,
        AccountFormWrapper__loading: appState.global.loading
      })}
    >
      <ContentHeader
        headingOne={PageNames.addNewAccount}
        back={PageUrls.accountCategories()}
        close={true}
        setFormDrawerOpen={setFormDrawerOpen}
      />
      <AccountForm
        accountCategories={accountCategories}
        onSubmit={(values) => helpers.addAccount(values, categoryIdParam, accountCategories, setError, navigate)}
        initialValues={initialFormValues}
      />
      {error && <div className="Form-Error">{error}</div>}
    </div>
  )
}

export default NewAccountFormWrapper
