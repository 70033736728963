import { FormValues } from '../../forms/AccountForm'
import { Account } from '../../types'
import endpoints from '../../utilities/endpoints'
import { client } from '../client'

type CreateAccount = (categoryId: number, accountParams: FormValues) => Promise<Account>

export const createAccount: CreateAccount = async (categoryId, accountParams) => {
  const response = await client.post<Account>(endpoints.accountCategoryAccounts(categoryId), accountParams)

  return response.data
}
