import endpoints from '../../utilities/endpoints'
import { client } from '../client'

type DeleteAccount = (accountId: number) => Promise<void>

export const deleteAccount: DeleteAccount = async (accountId) => {
  const response = await client.delete<void>(endpoints.account(accountId))

  return response.data
}
