import classNames from 'classnames'
import useSWR from 'swr'

import AccountCategoryList from '../../components/AccountCategoryList'
import ContentHeader from '../../components/ContentHeader'
import PageContent from '../../components/PageContent'
import { PageUrls } from '../../components/Router/routes'
import { useAppContext } from '../../contexts/AppContext'
import withAuthentication from '../../hoc/withAuthentication'
import { AccountCategory } from '../../types'
import endpoints from '../../utilities/endpoints'
import NewAccountFormWrapper from './NewAccountFormWrapper'

const NewAccount: React.FC = () => {
  const [appState] = useAppContext()

  const { data: accountCategoriesResponse } = useSWR<AccountCategory[]>(endpoints.accountCategories)
  const accountCategories = accountCategoriesResponse || []

  return (
    <>
      <ContentHeader headingOne="Kitchen Home" back={PageUrls.accountCategories()} />
      <PageContent
        className={classNames('NewAccount', {
          NewAccount__loading: appState.global.loading
        })}
      >
        <AccountCategoryList accountCategories={accountCategories} />

        <NewAccountFormWrapper accountCategories={accountCategories} />
      </PageContent>
    </>
  )
}

export default withAuthentication(NewAccount)
