import classNames from 'classnames'

import { Account, SaveStates } from '../../types'
import AccountRow from '../AccountRow'
import BigList from '../BigList'

interface Props {
  accounts: Account[]
  saveState?: SaveStates
}

const AccountList: React.FC<Props> = (props) => {
  const { accounts = [], saveState } = props
  return (
    <BigList className={classNames('AccountList', { BigList__empty: accounts.length === 0 })}>
      {accounts.length > 0 ? (
        accounts.map((account) => (
          <AccountRow account={account} key={`${account.balance_in_cents}_${account.id}`} saveState={saveState} />
        ))
      ) : (
        <li>No accounts</li>
      )}
    </BigList>
  )
}

export default AccountList
