import * as React from 'react'
import { NavigateFunction } from 'react-router'

import { deleteAccount as deleteAccountApi } from '../../api/deleteAccount'
import { editAccount as editAccountApi } from '../../api/editAccount'
import { PageUrls } from '../../components/Router/routes'
import { FormValues } from '../../forms/AccountForm'
import { setLoading } from '../../state/global/actions'
import { Currency } from '../../utilities/Currency'

export const editAccount = async (
  values: FormValues,
  accountIdParam: number,
  categoryId: number,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
  navigate: NavigateFunction
) => {
  try {
    const editedAccount = await editAccountApi(categoryId, accountIdParam, {
      ...values,
      balance_in_cents: `${Currency.dollarsToCents(parseFloat(values.balance_in_cents))}`
    })

    if (editedAccount) {
      navigate(PageUrls.accountCategory(categoryId))
    }
  } catch ({ message }) {
    setError(message as string)
  }
}

export const deleteAccount = async (
  categoryId: number,
  accountId: number,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
  navigate: NavigateFunction,
  dispatch: React.Dispatch<React.SetStateAction<any>>
) => {
  try {
    dispatch(setLoading(true))
    await deleteAccountApi(accountId)

    dispatch(setLoading(false))
    navigate(PageUrls.accountCategory(categoryId))
  } catch ({ message }) {
    setError(message as string)
    dispatch(setLoading(false))
  }
}
