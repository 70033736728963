import classNames from 'classnames'
import { HTMLProps } from 'react'

const H1: React.FC<HTMLProps<HTMLHeadingElement>> = ({ children, className, ...rest }) => (
  <h1 className={classNames('H1', className)} {...rest}>
    {children}
  </h1>
)

export default H1
