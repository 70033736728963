import { AnyAction, GlobalAppSettings } from '../../types'
import { SET_LOADING, SET_NAV_OPEN, SET_PAGE_CLASS } from './action_types'

const reducer = (state: GlobalAppSettings, action: AnyAction): GlobalAppSettings => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case SET_NAV_OPEN:
      return { ...state, navOpen: action.payload }
    case SET_PAGE_CLASS:
      return { ...state, pageClass: action.payload }
    default:
      return state
  }
}

export default reducer
