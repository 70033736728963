import { ReactComponent as MenuIcon } from '../../assets/img/nav-icon.svg'

type Props = {
  onClick: () => void
}

const MobileNavButton: React.FC<Props> = ({ onClick }) => {
  return (
    <button type="button" className="MobileNavButton" onClick={onClick}>
      <MenuIcon className="MobileNavButton-Icon" />
    </button>
  )
}

export default MobileNavButton
