import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as Logo } from '../../assets/img/horizontal_logo.svg'
import P from '../../components/Paragraph'
import { PageUrls } from '../../components/Router/routes'
import { useAppContext } from '../../contexts/AppContext'
import * as helpers from './helpers'

const Logout: React.FC = () => {
  const [appState, dispatch] = useAppContext()
  const [error, setError] = useState<string | undefined>(undefined)
  const navigate = useNavigate()
  const userId = appState.me?.id

  useEffect(() => {
    if (userId < 0) {
      navigate(PageUrls.home())
    } else {
      helpers.logout(setError, dispatch, userId)
    }
  }, [dispatch, navigate, userId, setError])

  return (
    <div className="Logout">
      <Logo className="Logout-Logo" />
      {appState.global.loading && <p className="Logout-P">Logging you out...</p>}

      {error && (
        <div className="Logout-ErrorsWrapper">
          <P>There was an issue logging you out. Refresh the page to try again.</P>
          <div className="Logout-Error">{error}</div>
        </div>
      )}
    </div>
  )
}

export default Logout
