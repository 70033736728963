import { createContext, Dispatch, useContext, useReducer } from 'react'

import { AppState } from '../../types'
import reducer, { initialState } from './reducer'

export const AppContext = createContext<[AppState, Dispatch<any>]>([initialState, () => {}])
export const useAppContext = () => useContext(AppContext)

interface Props {
  defaultState?: AppState
}
export const AppContextProvider: React.FC<Props> = ({ children, defaultState = initialState }) => {
  const [appState, dispatch] = useReducer(reducer, defaultState)

  return <AppContext.Provider value={[appState, dispatch]}>{children}</AppContext.Provider>
}
