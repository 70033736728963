import classNames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'

import { ReactComponent as AddIcon } from '../../assets/img/add.svg'

type Props = LinkProps & {
  text?: string
}

const AddButton: React.FC<Props> = ({ className, text, to, ...props }) => {
  return (
    <Link to={to} className={classNames('AddButton', className)} {...props}>
      <AddIcon />
      {text && <span className="AddButton-Text">{text}</span>}
    </Link>
  )
}

export default AddButton
