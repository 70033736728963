import classNames from 'classnames'

import { ReactComponent as ArrowIcon } from '../../assets/img/arrow.svg'

type Props = {
  className?: string
}

const GoIcon: React.FC<Props> = ({ className }) => {
  return (
    <span className={classNames('GoIcon', className)} role="img">
      <ArrowIcon />
    </span>
  )
}

export default GoIcon
