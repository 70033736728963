import { FC } from 'react'

import AccountCategories from '../../pages/AccountCategories'
import AccountCategoryAccounts from '../../pages/AccountCategoryAccounts'
import AccountCategoriesNewAccount from '../../pages/AccountCategoryNewAccount'
import AdvisorConnect from '../../pages/AdvisorConnect'
import EditAccount from '../../pages/EditAccount'
import FourOhFour from '../../pages/FourOhFour'
import Home from '../../pages/Home'
import Login from '../../pages/Login'
import Logout from '../../pages/Logout'
import NewAccount from '../../pages/NewAccount'
import NewAccountInterstitial from '../../pages/NewAccountInterstitial'
import NewLinkedAccountPage from '../../pages/NewLinkedAccount'
import { AppState } from '../../types'
import { icons } from '../../utilities/icons'

export interface RouteShape {
  component: FC
  navIcon: FC
  shouldShow: (state: AppState) => boolean
  path: (routeParam?: number | string, routeParamTwo?: number | string) => string
  title: string
}

export const PageNames: Record<string, string> = {
  addAccount: 'Add account',
  addNewAccount: 'Add new account',
  advisorConnect: 'Advisor Connect',
  editAccount: 'Edit account',
  fourOhFour: '404 page',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  wealthKitchen: 'Wealth Kitchen'
}

export const PageUrls: Record<string, RouteShape['path']> = {
  accountCategories: () => '/account_categories',
  addAccount: () => '/accounts/new',
  addLinkedAccount: () => '/accounts/new/linked',
  addAccountInterstitial: () => '/accounts/new_interstitial',
  advisorConnect: () => '/advisor_connect',
  accountCategory: (routeParam = ':account_category_id') => `/account_categories/${routeParam}`,
  addAccountToCategory: (routeParam = ':account_category_id') => `/account_categories/${routeParam}/accounts/new`,
  editAccount: (categoryId = ':account_category_id', accountId = ':account_id') =>
    `/account_categories/${categoryId}/accounts/${accountId}/edit`,
  fourOhFour: () => '*',
  home: () => '/',
  login: () => '/login',
  logout: () => '/logout',
  notFound: () => '/not-found'
}

export const routes: Record<string, RouteShape> = {
  accountCategories: {
    component: AccountCategories,
    navIcon: icons.home,
    path: PageUrls.accountCategories,
    shouldShow: () => true,
    title: PageNames.wealthKitchen
  },
  accountCategory: {
    component: AccountCategoryAccounts,
    navIcon: icons.generic,
    path: PageUrls.accountCategory,
    shouldShow: () => false,
    title: PageNames.accountCategory
  },
  advisorConnect: {
    component: AdvisorConnect,
    navIcon: icons.advisor,
    path: () => PageUrls.advisorConnect(),
    shouldShow: (appState) => !!appState.me.wealth_manager,
    title: PageNames.advisorConnect
  },
  newAccountByCategory: {
    component: AccountCategoriesNewAccount,
    navIcon: icons.generic,
    path: PageUrls.addAccountToCategory,
    shouldShow: () => false,
    title: PageNames.addAccount
  },
  editAccount: {
    component: EditAccount,
    navIcon: icons.generic,
    path: PageUrls.editAccount,
    shouldShow: () => false,
    title: PageNames.editAccount
  },
  fourOhFour: {
    component: FourOhFour,
    navIcon: icons.generic,
    path: PageUrls.fourOhFour,
    shouldShow: () => false,
    title: PageNames.fourOhFour
  },
  newAccountInterstitial: {
    component: NewAccountInterstitial,
    navIcon: icons.add,
    path: PageUrls.addAccountInterstitial,
    shouldShow: () => true,
    title: PageNames.addAccount
  },
  newAccount: {
    component: NewAccount,
    navIcon: icons.add,
    shouldShow: () => false,
    path: PageUrls.addAccount,
    title: PageNames.addAccount
  },
  newLinkedAccount: {
    component: NewLinkedAccountPage,
    navIcon: icons.add,
    shouldShow: () => false,
    path: PageUrls.addLinkedAccount,
    title: PageNames.addAccount
  },
  home: {
    component: Home,
    navIcon: icons.generic,
    path: PageUrls.home,
    shouldShow: () => true,
    title: PageNames.home
  },
  login: {
    component: Login,
    navIcon: icons.generic,
    path: PageUrls.login,
    shouldShow: (appState) => appState.me.id < 0,
    title: PageNames.login
  },
  logout: {
    component: Logout,
    navIcon: icons.logout,
    path: PageUrls.logout,
    shouldShow: (appState) => appState.me.id >= 0,
    title: PageNames.logout
  }
}
