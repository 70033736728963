import ContentHeader from '../../components/ContentHeader'
import PageContent from '../../components/PageContent'
import { PageNames, PageUrls } from '../../components/Router/routes'
import NewLinkedAccount from './NewLinkedAccount'

const NewLinkedAccountPage: React.FC = () => {
  return (
    <>
      <ContentHeader headingOne={PageNames.addNewAccount} back={PageUrls.addAccountInterstitial()} />
      <PageContent className="NewLinkedAccountPage-PageContent">
        <NewLinkedAccount />
      </PageContent>
    </>
  )
}

export default NewLinkedAccountPage
