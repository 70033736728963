import { FormikProps, withFormik } from 'formik'
import { useEffect } from 'react'

import Button from '../../components/Button'
import CategoryField from '../../components/CategoryField'
import Form from '../../components/Form'
import SubCategoryField from '../../components/SubCategoryField'
import { AccountCategory } from '../../types'
import TextFieldWithLabel from '../components/TextFieldWithLabel'
import { serializeFormFValues, validationSchema } from './helpers'

export interface FormValues {
  account_category_id: string | number
  balance_in_cents: string
  institution_name: string
  sub_category?: string
}

interface Props {
  accountCategories: AccountCategory[]
  displayCategoryField?: boolean
  fieldsReadOnly?: boolean
  initialValues: FormValues
  onSubmit: (values: FormValues) => Promise<void>
}

const defaultFieldLabels = { institutionName: 'Institution or entity name', currentBalance: 'Current balance' }
const realEstateFieldLabels = { institutionName: 'Property name', currentBalance: 'Property value' }

const AccountForm: React.FC<Props & FormikProps<FormValues>> = ({
  accountCategories,
  fieldsReadOnly,
  isSubmitting,
  isValid,
  displayCategoryField,
  setFieldValue,
  values
}) => {
  const activeFieldLabels = values.sub_category === 'Property' ? realEstateFieldLabels : defaultFieldLabels
  const activeCategory = accountCategories.find((category) => category.id === +values.account_category_id)
  const accountSubCategories = activeCategory?.sub_categories || []
  const accountSubCategoriesLength = accountSubCategories.length

  useEffect(() => {
    setFieldValue('has_sub_categories', accountSubCategoriesLength > 0)
  }, [accountSubCategoriesLength, setFieldValue])

  return (
    <Form className="AccountForm">
      {displayCategoryField && (
        <CategoryField
          accountCategories={accountCategories}
          className="Form-Field AccountForm-Field"
          name="account_category_id"
          readOnly={fieldsReadOnly}
        />
      )}

      <SubCategoryField
        name="sub_category"
        accountSubCategories={accountSubCategories}
        className="Form-Field AccountForm-Field"
        readOnly={fieldsReadOnly}
      />

      <TextFieldWithLabel
        element="AccountForm"
        fieldName="institution_name"
        labelText={activeFieldLabels.institutionName}
        readOnly={fieldsReadOnly}
      />

      <TextFieldWithLabel
        element="AccountForm"
        fieldName="balance_in_cents"
        labelText={activeFieldLabels.currentBalance}
        readOnly={fieldsReadOnly}
      />

      <Button className="Form-Button" disabled={!isValid || isSubmitting || fieldsReadOnly} type="submit">
        Submit
      </Button>
    </Form>
  )
}

export default withFormik<Props, FormValues>({
  displayName: 'AccountForm',
  handleSubmit: (values, { props }) => props.onSubmit(serializeFormFValues(values)),
  mapPropsToValues: ({ initialValues }) => initialValues,
  validateOnMount: true,
  validationSchema: validationSchema
})(AccountForm)
