import classNames from 'classnames'
import { ErrorMessage, Field } from 'formik'

import FieldLabel from '../FieldLabel'

type Props = {
  element: string
  fieldName: string
  labelText: string
  testId?: string
  type?: 'number' | 'password' | 'tel' | 'text'
  readOnly?: boolean
}

const TextFieldWithLabel: React.FC<Props> = ({
  element,
  fieldName,
  labelText,
  readOnly = false,
  testId = fieldName,
  type = 'text'
}) => {
  return (
    <>
      <FieldLabel className={`${element}-Label`} htmlFor={fieldName} labeltext={labelText}>
        <Field
          className={classNames('Form-Field', `${element}-Field`)}
          type={type}
          data-testid={testId}
          name={fieldName}
          aria-label={fieldName}
          readOnly={readOnly}
        />
      </FieldLabel>
      <ErrorMessage className="Form-Error" name={fieldName} component="div" aria-label="alert" />
    </>
  )
}

export default TextFieldWithLabel
