import classNames from 'classnames'

interface Props {
  className?: string
}

const PageContent: React.FC<Props> = ({ children, className }) => {
  return <div className={classNames('PageContent', className)}>{children}</div>
}

export default PageContent
