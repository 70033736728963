import classNames from 'classnames'
import { ErrorMessage, Field, FieldHookConfig } from 'formik'

import FieldLabel from '../../forms/components/FieldLabel'

type Props = FieldHookConfig<string> & {
  accountSubCategories: string[]
  readOnly?: boolean
}

const SubCategoryField: React.FC<Props> = (props) => {
  const { accountSubCategories, className, ...rest } = props
  return accountSubCategories.length > 0 ? (
    <>
      <FieldLabel
        className={classNames('SubCategory-Label')}
        htmlFor="sub_category"
        labeltext="Account or holding type"
      >
        <Field
          className={classNames('SubCategory-Field', className)}
          as="select"
          aria-label="sub_category"
          disabled={props.readOnly}
          {...rest}
        >
          <option value="" disabled>
            Select type
          </option>
          {accountSubCategories.map((categoryName) => {
            return (
              <option value={categoryName} key={categoryName}>
                {categoryName}
              </option>
            )
          })}
        </Field>
      </FieldLabel>

      <ErrorMessage className="Form-Error" name="sub_category" component="div" aria-label="alert" />
    </>
  ) : (
    <></>
  )
}

export default SubCategoryField
