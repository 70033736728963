import { useState } from 'react'

import { client } from '../../api/client'
import Button from '../../components/Button'
import ContentHeader from '../../components/ContentHeader'
import PageContent from '../../components/PageContent'
import P from '../../components/Paragraph'
import { useAppContext } from '../../contexts/AppContext'
import endpoints from '../../utilities/endpoints'

const Home: React.FC = () => {
  const [appState] = useAppContext()
  const [helloWorldData, setHelloWorldData] = useState<string>('')

  const onSubmit = async () => {
    const { data } = await client.get(endpoints.helloWorlds)
    setHelloWorldData(data.text)
  }

  return (
    <>
      <ContentHeader headingOne="Home" />

      <PageContent>
        <P>Home content.</P>
        <div className="Home-test">
          <Button onClick={onSubmit} disabled={appState.global.loading}>
            Get message {helloWorldData && '(again...)'}
          </Button>
          {appState.global.loading ? (
            <div className="Home-testResponse">
              <h5>Loading...</h5>
              <P>Loading a message from the robots...</P>
            </div>
          ) : (
            helloWorldData && (
              <div className="Home-testResponse">
                <h5>Response</h5>
                <P>{helloWorldData}</P>
              </div>
            )
          )}
        </div>
      </PageContent>
    </>
  )
}

export default Home
