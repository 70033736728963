export const endpoints = {
  accountCategories: '/v1/account_categories',
  accountCategoryAccounts: (accountCategoryId: number) => `/v1/account_categories/${accountCategoryId}/accounts`,
  accountCategoryAccount: (accountCategoryId: number, accountId: number) =>
    `/v1/account_categories/${accountCategoryId}/accounts/${accountId}`,
  createPlaidLinkToken: '/v1/plaid_link_tokens',
  createPlaidAccessToken: '/v1/plaid_access_tokens',
  accounts: () => '/v1/accounts/',
  account: (accountId: number) => `/v1/accounts/${accountId}`,
  plaidAccounts: '/v1/plaid_accounts',
  helloWorlds: '/v1/hello_worlds',
  me: '/v1/me',
  sessions: '/v1/sessions'
}

export default endpoints
