import { ReportHandler } from 'web-vitals'

interface WebVitalProps {
  id: string
  name: string
  value: number
}

interface GoogleAnalyticsProps {
  action: string
  category: string
  label: string
  value: number
  nonInteraction?: boolean
}

const GA_TRACKING_ID = process.env.REACT_APP_ANALYTICS_ID!

export const tagManagerArgs = {
  gtmId: GA_TRACKING_ID
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const analyticsEvent = ({ action, category, label, value, nonInteraction = false }: GoogleAnalyticsProps) => {
  // eslint-disable-next-line
  if (typeof window !== undefined && window.gtag !== undefined) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
      nonInteraction: nonInteraction,
      transport: 'beacon'
    })
  }
}

export const sendWebVitalsToAnalytics = ({ id, name, value }: WebVitalProps) => {
  analyticsEvent({
    action: name,
    category: 'Web Vitals',
    label: id, // id unique to current page load
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    nonInteraction: true // avoids affecting bounce rate
  })
}

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry)
      getFID(onPerfEntry)
      getFCP(onPerfEntry)
      getLCP(onPerfEntry)
      getTTFB(onPerfEntry)
    })
  }
}

export default reportWebVitals
