import classNames from 'classnames'
import { ButtonHTMLAttributes } from 'react'

import { ReactComponent as ArrowIcon } from '../../assets/img/arrow.svg'
import P from '../Paragraph'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  description?: string
}

const RowLinkButton: React.FC<Props> = ({ children, description, className, disabled = false, ...rest }) => {
  return (
    <div className={classNames('RowLinkButton-Wrapper', className, { [`RowLinkButton-Wrapper__disabled`]: disabled })}>
      <button className="RowLinkButton" {...rest} disabled={disabled}>
        {children}
        <ArrowIcon />
      </button>
      {description && <P className="RowLinkButton-P">{description}</P>}
    </div>
  )
}

export default RowLinkButton
