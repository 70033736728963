import { PlaidAccessTokenResponse } from '../../types'
import endpoints from '../../utilities/endpoints'
import { client } from '../client'

export interface Params {
  public_token: string
}

type CreateAccessToken = (params: Params) => Promise<PlaidAccessTokenResponse>

export const createAccessToken: CreateAccessToken = async (params) => {
  const response = await client.post<PlaidAccessTokenResponse>(endpoints.createPlaidAccessToken, params)

  return response.data
}
