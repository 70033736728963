import axios, { AxiosRequestConfig } from 'axios'

import { Storage } from '../utilities/storage'

const AXIOS_CONFIG: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL!,
  headers: {
    Accept: 'application/json'
  }
}
const client = axios.create(AXIOS_CONFIG)

client.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${Storage.getAuthToken()}`
  } else {
    config.headers = { Authorization: `Bearer ${Storage.getAuthToken()}` }
  }

  return config
})

export { client }
