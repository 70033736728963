import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import AccountCategoryList from '../../components/AccountCategoryList'
import ContentHeader from '../../components/ContentHeader'
import PageContent from '../../components/PageContent'
import P from '../../components/Paragraph'
import { PageUrls } from '../../components/Router/routes'
import { useAppContext } from '../../contexts/AppContext'
import { FormValues } from '../../forms/AccountForm'
import withAuthentication from '../../hoc/withAuthentication'
import { AccountCategory } from '../../types'
import endpoints from '../../utilities/endpoints'
import NewAccountFormWrapper from './NewAccountFormWrapper'

const AccountCategoryNewAccount: React.FC = () => {
  const [appState] = useAppContext()
  const { data: accountCategoriesResponse } = useSWR<AccountCategory[]>(endpoints.accountCategories)
  const accountCategories = accountCategoriesResponse || []
  const params = useParams<string>()

  const categoryIdParam = parseInt(params.account_category_id!)
  const accountCategory = accountCategories.find((category) => category.id === categoryIdParam)
  const initialFormValues: FormValues = {
    account_category_id: `${categoryIdParam}`,
    balance_in_cents: '',
    institution_name: '',
    sub_category: ''
  }

  return (
    <>
      <ContentHeader headingOne="Kitchen Home" back={PageUrls.accountCategories()} />
      <PageContent
        className={classNames('AccountCategoryNewAccount', {
          AccountCategoryNewAccount__loading: appState.global.loading
        })}
      >
        <AccountCategoryList accountCategories={accountCategories} />

        {accountCategory ? (
          <NewAccountFormWrapper accountCategories={accountCategories} initialFormValues={initialFormValues} />
        ) : (
          <P className="AccountCategoryNewAccount-P">No such category</P>
        )}
      </PageContent>
    </>
  )
}

export default withAuthentication(AccountCategoryNewAccount)
