import { Account } from '../../types'
import endpoints from '../../utilities/endpoints'
import { client } from '../client'

type GetPlaidAccounts = (access_token: string) => Promise<Account[]>

export const getPlaidAccounts: GetPlaidAccounts = async (access_token) => {
  const response = await client.get<Account[]>(endpoints.plaidAccounts, {
    params: {
      access_token: access_token
    }
  })

  return response.data
}
