import * as Yup from 'yup'

import { Currency } from '../../utilities/Currency'
import { FormValues } from '.'

export const validationSchema = Yup.object({
  account_category_id: Yup.string().required('Required'),
  balance_in_cents: Yup.string()
    .required()
    .matches(/^([0-9])+(\.[0-9]{2})?$/, 'Must be in USD format e.g. 12345.67')
    .required('Required'),
  institution_name: Yup.string().required('Required'),
  sub_category: Yup.string().when('has_sub_categories', {
    is: true,
    then: Yup.string().required('Required')
  })
})

export const liabilitySubCategories = ['Other Liability', 'Mortgage']

export const isLiability = (subCategory?: string): boolean => {
  return liabilitySubCategories.some((name) => subCategory === name)
}

export const convertNegativeLiabilities = (values: FormValues): FormValues => {
  const shouldBeNegativeValue = values.sub_category ? isLiability(values.sub_category) : false
  const balanceInCents = Currency.dollarsToCents(parseFloat(values.balance_in_cents))

  let amount = balanceInCents

  switch (true) {
    case shouldBeNegativeValue && balanceInCents > 0:
      amount = balanceInCents * -1
      break
    case !shouldBeNegativeValue && balanceInCents < 0:
      amount = balanceInCents * -1
      break
  }

  const payload = {
    account_category_id: values.account_category_id,
    balance_in_cents: (amount / 100).toFixed(2),
    institution_name: values.institution_name,
    sub_category: values.sub_category
  }

  return payload
}

export const serializeFormFValues = (values: FormValues): FormValues => {
  const apiFormValues = {
    account_category_id: values.account_category_id,
    balance_in_cents: values.balance_in_cents,
    institution_name: values.institution_name,
    sub_category: values.sub_category
  }

  return convertNegativeLiabilities(apiFormValues)
}
