import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Currency } from '../../utilities/Currency'
import AddButton from '../AddButton'
import BackButton from '../BackButton'
import CloseButton from '../CloseButton'
import H1 from '../H1'
import P from '../Paragraph'
import { PageUrls } from '../Router/routes'

type Props = {
  add?: string
  addText?: string
  accountCategoryTotalBalance?: number
  back?: string
  close?: boolean
  headingOne: string
  className?: string
  setFormDrawerOpen?: (value: boolean) => void
}

const ContentHeader: React.FC<Props> = ({
  add,
  addText,
  accountCategoryTotalBalance,
  back,
  className,
  close,
  setFormDrawerOpen,
  headingOne
}) => {
  const navigate = useNavigate()
  const onClick = () => {
    setFormDrawerOpen && setFormDrawerOpen(false)

    navigate(PageUrls.accountCategories())
  }

  return (
    <div
      className={classNames(
        'ContentHeader',
        { ContentHeader__withAddLink: add },
        { ContentHeader__withAddText: addText },
        { ContentHeader__withBackLink: back },
        className
      )}
    >
      {back && <BackButton to={back} />}
      {add && <AddButton to={add} text={addText} />}
      {close && setFormDrawerOpen && <CloseButton onClick={onClick} />}

      <H1 className="ContentHeader-H1">{headingOne ?? ''}</H1>
      <P className="ContentHeader-P__ContentHeaderBalance">
        {Currency.centsToDollars(accountCategoryTotalBalance ?? 0)}
      </P>
    </div>
  )
}

export default ContentHeader
