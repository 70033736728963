import classNames from 'classnames'
import { Link, LinkProps } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from '../../assets/img/arrow.svg'
import P from '../Paragraph'

type Props = LinkProps & {
  className?: string
  description?: string
  disabled?: boolean
}

const RowLink: React.FC<Props> = ({ children, description, className, disabled = false, to, ...rest }) => {
  return (
    <div
      className={classNames(
        'RowLink-Wrapper',
        { [`RowLink-Wrapper__${className}`]: className },
        { [`RowLink-Wrapper__disabled`]: disabled }
      )}
    >
      <Link className={classNames('RowLink', { RowLink__disabled: disabled })} to={disabled ? '' : to} {...rest}>
        {children}
        <ArrowIcon />
      </Link>
      {description && <P className="RowLink-P">{description}</P>}
    </div>
  )
}

export default RowLink
