import { ReactComponent as Logo } from '../../assets/img/horizontal_logo.svg'

const Drawer: React.FC = ({ children }) => {
  return (
    <aside className="Drawer">
      <div className="Drawer-Top">{children}</div>

      <Logo className="Drawer-Logo" />
    </aside>
  )
}

export default Drawer
