import { AnyAction, Me } from '../../types'
import { LOGOUT, SET_ME } from './action_types'

const reducer = (state: Me, action: AnyAction): Me => {
  switch (action.type) {
    case SET_ME:
      return action.payload
    case LOGOUT:
      return {
        email: '',
        id: -1
      }
    default:
      return state
  }
}

export default reducer
