import React from 'react'
import { SWRConfig as _SWRConfig } from 'swr'

import { client } from '../api/client'

export type Fetcher = <T>(resource: string) => Promise<T>
const fetcher: Fetcher = (resource) => client.get(resource).then((res) => res.data)

export const SWRConfig: React.FC = ({ children }) => (
  // eslint-disable-next-line react/jsx-pascal-case
  <_SWRConfig value={{ fetcher }}>{children}</_SWRConfig>
)
