import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as Logo } from '../../assets/img/horizontal_logo.svg'
import LoginForm from '../../forms/LoginForm'
import * as helpers from './helpers'

const Login: React.FC = () => {
  const [error, setError] = useState<string | undefined>(undefined)
  const navigate = useNavigate()

  return (
    <div className="Login">
      <Logo className="Login-Logo" />

      <div className="Login-FormWrapper">
        <LoginForm onSubmit={(values) => helpers.login(setError, navigate, values)} />
        {error && <div className="Form-Error">{error}</div>}
      </div>
    </div>
  )
}

export default Login
