import classNames from 'classnames'
import { HTMLProps } from 'react'

const H2: React.FC<HTMLProps<HTMLHeadingElement>> = ({ children, className, ...rest }) => (
  <h2 className={classNames('H2', className)} {...rest}>
    {children}
  </h2>
)

export default H2
