import ContentHeader from '../../components/ContentHeader'
import PageContent from '../../components/PageContent'
import P from '../../components/Paragraph'
import { PageNames } from '../../components/Router/routes'

const FourOhFour: React.FC = () => {
  return (
    <>
      <ContentHeader headingOne={PageNames.fourOhFour} />
      <PageContent>
        <P>... nothing here.</P>
      </PageContent>
    </>
  )
}

export default FourOhFour
