import { FormValues } from '../../forms/LoginForm'
import { LoginResponse } from '../../types'
import endpoints from '../../utilities/endpoints'
import { client } from '../client'

type Login = (formValues: FormValues) => Promise<LoginResponse>

export const createSession: Login = async (formValues) => {
  const response = await client.post<LoginResponse>(endpoints.sessions, formValues)

  return response.data
}
