import classNames from 'classnames'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Drawer from '../../components/Drawer'
import MobileNavButton from '../../components/MobileNavButton'
import Navigation from '../../components/Navigation'
import NavMask from '../../components/NavMask'
import { useAppContext } from '../../contexts/AppContext'
import { setNavOpen, setPageClass } from '../../state/global/actions'

const PrimaryLayout: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children, className, ...rest }) => {
  const [appState, dispatch] = useAppContext()
  const {
    global: { loading, pageClass, navOpen }
  } = appState
  const location = useLocation()
  const transformedPageClass = location.pathname.substring(1, location.pathname.length).replaceAll('/', '__')

  const onMobileNavToggle = () => {
    dispatch(setNavOpen(!navOpen))
  }

  useEffect(() => {
    dispatch(setNavOpen(false))
    dispatch(setPageClass(!!transformedPageClass.length ? transformedPageClass : 'root'))
  }, [dispatch, transformedPageClass])

  return (
    <div
      className={classNames(
        'PrimaryLayout',
        navOpen && 'global-state-nav-open',
        `global-state-page-class-${pageClass}`,
        loading && 'global-state-loading'
      )}
      {...rest}
    >
      <main className="PrimaryLayout-Content">{children}</main>
      <Drawer>
        <Navigation />
      </Drawer>

      <MobileNavButton onClick={onMobileNavToggle} />
      <NavMask onClick={onMobileNavToggle} />
    </div>
  )
}

export default PrimaryLayout
