import classNames from 'classnames'
import { Dispatch, SetStateAction } from 'react'

import Button from '../../components/Button'
import ConfirmationModal from '../../components/ConfirmationModal'
import ContentHeader from '../../components/ContentHeader'
import { PageNames, PageUrls } from '../../components/Router/routes'
import { useAppContext } from '../../contexts/AppContext'
import AccountForm, { FormValues } from '../../forms/AccountForm'
import { useModal } from '../../hooks/useModal'
import { AccountCategory } from '../../types'

interface Props {
  accountCategories: AccountCategory[]
  deleteAccount?: () => Promise<void>
  error?: string
  formDrawerOpen: boolean
  initialFormValues: FormValues
  onSubmit: (values: FormValues) => Promise<void>
  readOnly?: boolean
  setFormDrawerOpen: Dispatch<SetStateAction<boolean>>
}

const EditAccountFormWrapper: React.FC<Props> = ({
  accountCategories,
  deleteAccount,
  error,
  formDrawerOpen,
  initialFormValues,
  onSubmit,
  readOnly,
  setFormDrawerOpen
}) => {
  const { isShown, toggleModal } = useModal()
  const [appState] = useAppContext()

  return (
    <div
      className={classNames('EditAccountFormWrapper', 'AccountFormWrapper', {
        AccountFormWrapper__drawerOpen: formDrawerOpen
      })}
    >
      <ContentHeader
        back={PageUrls.accountCategory(initialFormValues.account_category_id)}
        close={true}
        headingOne={PageNames.editAccount}
        setFormDrawerOpen={setFormDrawerOpen}
      />

      <AccountForm
        accountCategories={accountCategories}
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        fieldsReadOnly={readOnly}
      />

      {deleteAccount && (
        <>
          <Button
            className="Form-Button Button__link"
            type="button"
            onClick={toggleModal}
            disabled={appState.global.loading}
          >
            Delete
          </Button>

          <ConfirmationModal
            confirmationButtonText="Delete"
            headerText="Delete Account"
            isShown={isShown}
            loading={appState.global.loading}
            message="Are you sure that you want to delete this account?"
            onCancel={toggleModal}
            onConfirm={() => deleteAccount()}
          />
        </>
      )}
      {error && <div className="Form-Error">{error}</div>}
    </div>
  )
}

export default EditAccountFormWrapper
