import './assets/styles/global.scss'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import { AppContextProvider } from './contexts/AppContext'
import { SWRConfig } from './swr'
import reportWebVitals, { sendWebVitalsToAnalytics, tagManagerArgs } from './utilities/analytics'

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs)
  reportWebVitals(sendWebVitalsToAnalytics)
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_INIT_DNS,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.9
  })
}

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig>
      <AppContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppContextProvider>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
)
