import { PlaidLinkTokenResponse } from '../../types'
import endpoints from '../../utilities/endpoints'
import { client } from '../client'

type CreateLinkToken = () => Promise<PlaidLinkTokenResponse>

export const createLinkToken: CreateLinkToken = async () => {
  const response = await client.post<PlaidLinkTokenResponse>(endpoints.createPlaidLinkToken)

  return response.data
}
