import { PageUrls } from '../Router/routes'
import RowLink from '../RowLink'
import RowLinkButton from '../RowLinkButton'

type Props = {
  disabled?: boolean
  openPlaid: Function
}

const AddAccountRowLinks: React.FC<Props> = ({ disabled = false, openPlaid }) => (
  <div className="AddAccountRowLinks">
    <RowLinkButton
      description="Add An Online Account To Wealth Kitchen"
      disabled={disabled}
      onClick={() => openPlaid()}
    >
      Linked Account
    </RowLinkButton>
    <RowLink description="Manually Add An Account With Balance" to={PageUrls.addAccount()} disabled={disabled}>
      Unlinked Account
    </RowLink>
  </div>
)

export default AddAccountRowLinks
