import classNames from 'classnames'

import { AccountCategory } from '../../types'
import AccountCategoryRow from '../AccountCategoryRow'
import BigList from '../BigList'

interface Props {
  accountCategories: AccountCategory[]
}

const AccountCategoryList: React.FC<Props> = ({ accountCategories }) => {
  const hasCategories = accountCategories.length > 0

  return (
    <BigList
      className={classNames('AccountCategoryList', {
        AccountCategoryList__empty: !hasCategories
      })}
    >
      {hasCategories ? (
        accountCategories.map((accountCategory) => (
          <AccountCategoryRow
            accountCategory={accountCategory}
            className={`AccountCategoryList__${accountCategory.name}`}
            key={accountCategory.id}
          />
        ))
      ) : (
        <li>No Account Categories</li>
      )}
    </BigList>
  )
}

export default AccountCategoryList
