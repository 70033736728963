import { FormValues } from '../../forms/AccountForm'
import { Account } from '../../types'
import endpoints from '../../utilities/endpoints'
import { client } from '../client'

type EditAccount = (categoryId: number, accountId: number, accountParams: FormValues) => Promise<Account>

export const editAccount: EditAccount = async (categoryId, accountId, accountParams) => {
  const response = await client.patch<Account>(endpoints.accountCategoryAccount(categoryId, accountId), accountParams)

  return response.data
}
