import Button from '../Button'
import Modal from '../Modal'
import P from '../Paragraph'

export interface Props {
  confirmationButtonText: string
  headerText: string
  isShown: boolean
  loading: boolean | undefined
  message: string
  onCancel: () => void
  onConfirm: () => void
}

const ConfirmationModal: React.FC<Props> = ({
  confirmationButtonText,
  headerText,
  isShown,
  loading,
  message,
  onCancel,
  onConfirm
}) => {
  return (
    <Modal headerText={headerText} hide={onCancel} isShown={isShown}>
      <div className="Modal-Content">
        <P>{message}</P>
      </div>
      <div className="Modal-Footer">
        <Button type="button" onClick={() => onConfirm()} disabled={loading} variant="danger" size="sm">
          {confirmationButtonText}
        </Button>
        <Button data-dismiss="modal" aria-label="Cancel" onClick={onCancel} size="sm">
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
