import classNames from 'classnames'
import { ErrorMessage, Field, FieldHookConfig, useField, useFormikContext } from 'formik'
import { useEffect } from 'react'

import FieldLabel from '../../forms/components/FieldLabel'
import { AccountCategory } from '../../types'

type Props = FieldHookConfig<string> & {
  accountCategories: AccountCategory[]
  readOnly?: boolean
}

const CategoryField: React.FC<Props> = (props) => {
  const { accountCategories, className, ...rest } = props
  const [field] = useField(props.name)
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    setFieldValue('sub_category', '', true)
  }, [field.value, setFieldValue])

  return (
    <>
      <FieldLabel className={classNames('FieldLabel-Category')} htmlFor="account_category_id" labeltext="Category">
        <Field
          as="select"
          className={classNames('CategoryField-Field', className)}
          aria-label="account_category_id"
          disabled={props.readOnly}
          {...rest}
        >
          <option value="" disabled>
            Select category
          </option>
          {accountCategories.map((category) => {
            return (
              <option value={category.id} key={`${category.id}`}>
                {category.name}
              </option>
            )
          })}
        </Field>
      </FieldLabel>

      <ErrorMessage className="Form-Error" name="account_category_id" component="div" aria-label="alert" />
    </>
  )
}

export default CategoryField
