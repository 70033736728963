import endpoints from '../../utilities/endpoints'
import { client } from '../client'

type Logout = (userId: number) => Promise<void>

export const deleteSession: Logout = async (userId) => {
  const response = await client.delete<undefined>(`${endpoints.sessions}/${userId}`)

  return response.data
}
