import classNames from 'classnames'
import { Form as FormikForm } from 'formik'

interface Props {
  className?: string
}

const Form: React.FC<Props> = ({ children, className }) => (
  <FormikForm className={classNames('Form', className)}>{children}</FormikForm>
)

export default Form
