import classNames from 'classnames'

import { useAppContext } from '../../contexts/AppContext'
import NavigationLink from '../NavigationLink'
import { routes } from '../Router/routes'

const Navigation: React.FC<React.HTMLProps<HTMLElement>> = ({ className, ...rest }) => {
  const [appState] = useAppContext()

  return (
    <nav className={classNames('Navigation', className)} {...rest}>
      {Object.entries(routes).map(
        ([route, shape]) =>
          shape.shouldShow(appState) && (
            <NavigationLink className={`NavigationLink__${route}`} to={shape.path()} key={`NavigationLink__${route}`}>
              <span className="NavigationLink-Icon">
                <shape.navIcon />
              </span>
              {shape.title}
            </NavigationLink>
          )
      )}
    </nav>
  )
}

export default Navigation
