import * as React from 'react'

import { deleteSession } from '../../api/deleteSession'
import { setLoading } from '../../state/global/actions'
import { logout as logoutAction } from '../../state/me/actions'
import { Storage } from '../../utilities/storage'

export const logout = async (
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
  dispatch: React.Dispatch<any>,
  userId: number
): Promise<void> => {
  try {
    dispatch(setLoading(true))
    await deleteSession(userId)

    Storage.deleteAuthToken()
    Storage.deleteRedirectPath()
    dispatch(logoutAction())
    dispatch(setLoading(false))
  } catch ({ status, message }) {
    setError(message as string)
    dispatch(setLoading(false))
  }
}
