import Router from './components/Router'
import PrimaryLayout from './layouts/PrimaryLayout'

const App: React.FC = () => {
  return (
    <PrimaryLayout>
      <Router />
    </PrimaryLayout>
  )
}

export default App
