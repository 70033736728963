import classNames from 'classnames'
import { useEffect, useState } from 'react'

import AccountList from '../../components/AccountList'
import ContentHeader from '../../components/ContentHeader'
import { PageUrls } from '../../components/Router/routes'
import WealthManagerInfo from '../../components/WealthManagerInfo'
import { useAppContext } from '../../contexts/AppContext'
import { Account, AccountCategory, SaveStates } from '../../types'
import AccountCategoryDonutGraph from './AccountCategoryDonutGraph'

interface Props {
  accounts: Account[]
  accountCategory: AccountCategory
  saveStateClass?: SaveStates
}

const AccountsWrapper: React.FC<Props> = ({ accounts, accountCategory, saveStateClass }) => {
  const [appState] = useAppContext()
  const [formDrawerOpen, setFormDrawerOpen] = useState<boolean>(false)

  useEffect(() => {
    setFormDrawerOpen(true)
  }, [])

  return (
    <div
      className={classNames('AccountsWrapper', {
        AccountsWrapper__drawerOpen: formDrawerOpen,
        AccountsWrapper__loading: appState.global.loading
      })}
    >
      <ContentHeader
        add={PageUrls.addAccountToCategory(accountCategory.id)}
        back={PageUrls.accountCategories()}
        className="ContentHeader__AccountsWrapper"
        close={true}
        headingOne={accountCategory.name}
        setFormDrawerOpen={setFormDrawerOpen}
        accountCategoryTotalBalance={accountCategory.total_accounts_balance_in_cents}
      />
      <div className="AccountsWrapper-ContentPane">
        <AccountList accounts={accounts} saveState={saveStateClass} />
        <AccountCategoryDonutGraph accountCategory={accountCategory} />
        <WealthManagerInfo />
      </div>
    </div>
  )
}

export default AccountsWrapper
