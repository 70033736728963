const AUTH_TOKEN = 'auth_token'
const REDIRECT_PATH = 'redirect_path'

export const Storage = {
  deleteAuthToken: () => localStorage.removeItem(AUTH_TOKEN),
  getAuthToken: () => localStorage.getItem(AUTH_TOKEN),
  setAuthToken: (token: string) => localStorage.setItem(AUTH_TOKEN, token),
  getRedirectPath: () => sessionStorage.getItem(REDIRECT_PATH),
  setRedirectPath: (path: string) => sessionStorage.setItem(REDIRECT_PATH, path),
  deleteRedirectPath: () => sessionStorage.removeItem(REDIRECT_PATH)
}
