import { Navigate, useParams } from 'react-router-dom'
import useSWR from 'swr'

import { PageUrls } from '../../components/Router/routes'
import { useAppContext } from '../../contexts/AppContext'
import withAuthentication from '../../hoc/withAuthentication'
import { Account, AccountCategory } from '../../types'
import endpoints from '../../utilities/endpoints'
import AccountCategoryAccounts from './AccountCategoryAccounts'

const AccountCategoryAccountsPage: React.FC = () => {
  const [appState] = useAppContext()
  const { data: accountCategoriesResponse } = useSWR<AccountCategory[]>(endpoints.accountCategories)
  const accountCategories = accountCategoriesResponse || []
  const params = useParams()
  const categoryIdParam = parseInt(params.account_category_id!)
  const accountCategory = accountCategories && accountCategories.find((category) => category.id === categoryIdParam)
  const { data: accountsResponse, error } = useSWR<Account[]>(endpoints.accountCategoryAccounts(categoryIdParam))
  if (error) {
    return <Navigate to={PageUrls.notFound()} />
  }

  const accounts = accountsResponse || []

  return (
    <AccountCategoryAccounts
      accountCategories={accountCategories}
      accountCategory={accountCategory}
      accounts={accounts}
      loading={appState.global.loading}
    />
  )
}

export default withAuthentication(AccountCategoryAccountsPage)
