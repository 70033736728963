import classNames from 'classnames'

import { AccountCategory } from '../../types'
import { Currency } from '../../utilities/Currency'
import AddIcon from '../AddIcon'
import BigRow from '../BigRow'
import GoIcon from '../GoIcon'
import { PageUrls } from '../Router/routes'

export interface Props {
  className: string
  accountCategory: AccountCategory
}

const AccountCategoryRow: React.FC<Props> = (props) => {
  const { id, accounts_count, name, total_accounts_balance_in_cents } = props.accountCategory

  const hasAccounts = accounts_count > 0
  const rowLink = hasAccounts ? PageUrls.accountCategory(id) : PageUrls.addAccountToCategory(id)

  const actionComponent = (
    <div className="BigRow__action AccountCategoryRow-Action">
      {hasAccounts ? (
        <GoIcon className="AccountCategoryRow-Action-Icon__go" />
      ) : (
        <AddIcon className="AccountCategoryRow-Action-Icon__add" />
      )}
    </div>
  )

  return (
    <BigRow
      className={classNames('AccountCategoryRow', props.className, { AccountCategoryRow__noAccounts: !hasAccounts })}
      title={name}
      description={Currency.centsToDollars(total_accounts_balance_in_cents)}
      actionComponent={actionComponent}
      link={rowLink}
    />
  )
}

export default AccountCategoryRow
