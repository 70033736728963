import classNames from 'classnames'
import { PropsWithChildren } from 'react'

export type Props = PropsWithChildren<unknown> & {
  className?: string
}

const BigList: React.FC<Props> = (props) => {
  const { children, className } = props

  return <ul className={classNames('BigList', className)}>{children}</ul>
}

export default BigList
