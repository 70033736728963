import ContentHeader from '../../components/ContentHeader'
import PageContent from '../../components/PageContent'
import { PageNames, PageUrls } from '../../components/Router/routes'
import RowLink from '../../components/RowLink'

const NewAccountInterstitial: React.FC = () => {
  return (
    <>
      <ContentHeader headingOne={PageNames.addNewAccount} back={PageUrls.accountCategories()} />
      <PageContent className="NewAccountInterstitial-PageContent">
        <RowLink description="Add An Online Account To Wealth Kitchen" to={PageUrls.addLinkedAccount()}>
          Linked Account
        </RowLink>
        <RowLink description="Manually Add An Account With Balance" to={PageUrls.addAccount()}>
          Unlinked Account
        </RowLink>
      </PageContent>
    </>
  )
}

export default NewAccountInterstitial
