import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

import { ReactComponent as CloseIcon } from '../../assets/img/close.svg'
import H2 from '../H2'

export type Props = PropsWithChildren<{
  headerText: string
  hide: () => void
  isShown: boolean
}>

const Modal: React.FC<Props> = ({ headerText, hide, isShown, children }) => {
  const modal = (
    <>
      <div className="Modal-Backdrop" />
      <button className="Modal-Close" onClick={hide}>
        <CloseIcon className="Modal-Close-Icon" />
      </button>
      <div className="Modal-Wrapper" aria-modal aria-labelledby={headerText} tabIndex={-1} role="dialog">
        <div className="Modal">
          <div className="Modal-Header">
            <H2 className="Modal-HeaderText">{headerText}</H2>
          </div>
          {children}
        </div>
      </div>
    </>
  )

  return isShown ? createPortal(modal, document.body) : null
}

export default Modal
