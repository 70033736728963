import { FC, useEffect, useState } from 'react'
import { Navigate, RouteProps, useLocation } from 'react-router-dom'

import { client } from '../api/client'
import Loading from '../components/Loading'
import { PageUrls } from '../components/Router/routes'
import { useAppContext } from '../contexts/AppContext'
import { setLoading } from '../state/global/actions'
import { setMe } from '../state/me/actions'
import { Me } from '../types'
import endpoints from '../utilities/endpoints'
import { Storage } from '../utilities/storage'

export default function withAuthentication<P>(Komponent: FC<P & RouteProps>) {
  const Authenticated = (props: P) => {
    const [appState, dispatch] = useAppContext()
    const [isReady, setIsReady] = useState(false)

    const location = useLocation()

    const getMeFromAPI = async () => {
      try {
        const { data: me } = await client.get<Me>(endpoints.me)

        me ? dispatch(setMe(me)) : Storage.setRedirectPath(location.pathname)
      } catch {
        Storage.setRedirectPath(location.pathname)
      } finally {
        dispatch(setLoading(false))
        setIsReady(true)
      }
    }

    useEffect(() => {
      dispatch(setLoading(true))

      if (appState.me.email.length === 0) {
        getMeFromAPI()
      } else {
        dispatch(setLoading(false))
        setIsReady(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (appState.global.loading || !isReady) {
      return <Loading />
    }

    // have session and not loading
    if (appState.me.email.length > 0) {
      return <Komponent {...props} />
    }

    // not loading from api and no session
    return <Navigate to={PageUrls.login()} />
  }

  return Authenticated
}
