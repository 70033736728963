export const Currency = {
  centsToDollars: (cents: number): string => {
    return new Intl.NumberFormat('en-EN', {
      currency: 'USD',
      style: 'currency',
      maximumFractionDigits: 2
    }).format(cents / 100.0)
  },
  centsToDollarsRaw: (cents: number): string => {
    return new Intl.NumberFormat('en-EN', {
      style: 'decimal',
      useGrouping: false,
      maximumFractionDigits: 2
    }).format(cents / 100.0)
  },
  dollarsToCents: (dollars: number): number => parseFloat(`${dollars * 100}`)
}
