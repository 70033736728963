import classNames from 'classnames'

import AccountCategoryList from '../../components/AccountCategoryList'
import ContentHeader from '../../components/ContentHeader'
import Loading from '../../components/Loading'
import PageContent from '../../components/PageContent'
import { Account, AccountCategory } from '../../types'
import AccountsWrapper from './AccountsWrapper'

interface Props {
  accountCategories: AccountCategory[]
  accountCategory?: AccountCategory
  accounts: Account[]
  loading: boolean
}

const AccountCategoryAccounts: React.FC<Props> = (props) => {
  if (!props.accountCategory) return <Loading />

  return (
    <>
      <ContentHeader headingOne="Kitchen Home" className="ContentHeader__AccountCategoryAccounts" />
      <PageContent
        className={classNames('AccountCategoryAccounts', {
          AccountCategoryNewAccount__loading: props.loading
        })}
      >
        <AccountCategoryList accountCategories={props.accountCategories} />
        <AccountsWrapper accounts={props.accounts} accountCategory={props.accountCategory} />
      </PageContent>
    </>
  )
}

export default AccountCategoryAccounts
