import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'

import AccountCategoryList from '../../components/AccountCategoryList'
import ContentHeader from '../../components/ContentHeader'
import Loading from '../../components/Loading'
import PageContent from '../../components/PageContent'
import { PageUrls } from '../../components/Router/routes'
import { useAppContext } from '../../contexts/AppContext'
import { FormValues } from '../../forms/AccountForm'
import withAuthentication from '../../hoc/withAuthentication'
import { Account, AccountCategory } from '../../types'
import { Currency } from '../../utilities/Currency'
import endpoints from '../../utilities/endpoints'
import EditAccountFormWrapper from './EditAccountFormWrapper'
import * as helpers from './helpers'

const EditAccount: React.FC = () => {
  const [appState, dispatch] = useAppContext()
  const [formDrawerOpen, setFormDrawerOpen] = useState<boolean>(false)
  const { data: accountCategoriesResponse } = useSWR<AccountCategory[]>(endpoints.accountCategories)
  const accountCategories = accountCategoriesResponse || []
  const navigate = useNavigate()
  const params = useParams<string>()

  const categoryIdParam = parseInt(params.account_category_id!)
  const accountIdParam = parseInt(params.account_id!)

  const accountCategory = accountCategories.find((category) => category.id === categoryIdParam)

  const [error, setError] = useState<string | undefined>(undefined)

  const { data, error: getAccountsError } = useSWR<Account[]>(endpoints.accountCategoryAccounts(categoryIdParam))

  useEffect(() => {
    setFormDrawerOpen(true)
  }, [])

  if (getAccountsError) {
    return <Navigate to={PageUrls.notFound()} />
  }

  const accounts = data || []
  const account = accounts.find((account) => account.id === accountIdParam)

  if (!accountCategory || !account) return <Loading />

  const onSubmit = (values: FormValues) =>
    helpers.editAccount(values, accountIdParam, categoryIdParam, setError, navigate)

  const onDelete = () => helpers.deleteAccount(categoryIdParam, accountIdParam, setError, navigate, dispatch)

  const initialFormValues: FormValues = {
    account_category_id: `${categoryIdParam}`,
    balance_in_cents: Currency.centsToDollarsRaw(account.balance_in_cents),
    institution_name: account.institution_name,
    sub_category: account.sub_category
  }

  return (
    <>
      <ContentHeader headingOne="Kitchen Home" back={PageUrls.accountCategories()} />
      <PageContent
        className={classNames('EditAccount', {
          EditAccount__loading: appState.global.loading
        })}
      >
        <AccountCategoryList accountCategories={accountCategories} />

        <EditAccountFormWrapper
          accountCategories={accountCategories}
          error={error}
          formDrawerOpen={formDrawerOpen}
          initialFormValues={initialFormValues}
          setFormDrawerOpen={setFormDrawerOpen}
          onSubmit={onSubmit}
          deleteAccount={onDelete}
          readOnly={appState.global.loading || !!account.plaid_account_id}
        />
      </PageContent>
    </>
  )
}

export default withAuthentication(EditAccount)
