import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ContentHeader from '../../components/ContentHeader'
import { PageNames, PageUrls } from '../../components/Router/routes'
import { useAppContext } from '../../contexts/AppContext'
import AccountForm, { FormValues } from '../../forms/AccountForm'
import { AccountCategory } from '../../types'
import { addAccount } from './helpers'

interface Props {
  accountCategories: AccountCategory[]
}

const NewAccountFormWrapper: React.FC<Props> = ({ accountCategories }) => {
  const [appState] = useAppContext()
  const [formDrawerOpen, setFormDrawerOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const [error, setError] = useState<string | undefined>(undefined)

  const initialFormValues: FormValues = {
    account_category_id: '',
    sub_category: '',
    institution_name: '',
    balance_in_cents: ''
  }

  useEffect(() => {
    setFormDrawerOpen(true)
  }, [])

  return (
    <div
      className={classNames('NewAccountFormWrapper', 'AccountFormWrapper', {
        AccountFormWrapper__drawerOpen: formDrawerOpen,
        AccountFormWrapper__loading: appState.global.loading
      })}
    >
      <ContentHeader
        headingOne={PageNames.addNewAccount}
        back={PageUrls.accountCategories()}
        close={true}
        setFormDrawerOpen={setFormDrawerOpen}
      />
      <AccountForm
        accountCategories={accountCategories}
        displayCategoryField
        initialValues={initialFormValues}
        onSubmit={(values: FormValues) => {
          return addAccount(values, accountCategories, setError, navigate)
        }}
      />
      {error && <div className="Form-Error">{error}</div>}
    </div>
  )
}

export default NewAccountFormWrapper
