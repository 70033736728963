import classNames from 'classnames'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'sm' | 'lg'
  variant?: 'normal' | 'fullWidth' | 'link' | 'danger'
}

const Button: React.FC<Props> = ({ children, className, size, variant = 'normal', ...rest }) => {
  return (
    <button
      className={classNames('Button', className, {
        Button__fullWidth: variant === 'fullWidth',
        Button__link: variant === 'link',
        Button__danger: variant === 'danger',
        Button__sm: size === 'sm',
        Button__lg: size === 'lg'
      })}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
