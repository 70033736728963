import { createLinkedAccounts } from '../../api/createLinkedAccounts'
import { Account, SaveStates } from '../../types'

export const addLinkedAccounts = async (
  linkedAccounts: Account[],
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
  setSaveState: React.Dispatch<React.SetStateAction<SaveStates>>
) => {
  setSaveState('saving')

  try {
    const newLinkedAccounts = await createLinkedAccounts(linkedAccounts)

    if (newLinkedAccounts) {
      setSaveState('saved')
    }
  } catch ({ message }) {
    setError(message as string)
  }
}
