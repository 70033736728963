import DonutGraph, { Data } from '../../components/DonutGraph'
import { AccountCategory } from '../../types'

interface Props {
  accountCategory: AccountCategory
}

const AccountCategoryDonutGraph: React.FC<Props> = ({ accountCategory: { percent_of_total_assets } }) => {
  const categoryPercentageOfTotalAssets: number = percent_of_total_assets
  const leftoverPercent = 100 - categoryPercentageOfTotalAssets

  const graphData: Data[] = [
    { x: '', y: categoryPercentageOfTotalAssets },
    { x: '', y: leftoverPercent }
  ]

  return (
    <DonutGraph
      categoryPercentageOfTotalAssets={categoryPercentageOfTotalAssets}
      className="AccountCategoryDonutGraph"
      data={graphData}
    />
  )
}

export default AccountCategoryDonutGraph
