import { Route, Routes } from 'react-router-dom'

import { routes } from './routes'

const Router: React.FC = () => {
  return (
    <Routes>
      {Object.entries(routes).map(([route, shape]) => (
        <Route path={shape.path()} element={<shape.component />} key={`${route}-Route`} />
      ))}
    </Routes>
  )
}

export default Router
