import classNames from 'classnames'
import { Link } from 'react-router-dom'

import H2 from '../H2'
import P from '../Paragraph'

export interface Props {
  actionComponent?: React.ReactNode
  className?: string
  description: string
  title: string
  link?: string
}

const BigRow: React.FC<Props> = (props) => {
  const { actionComponent, className, description, link, title } = props

  const bigRowContent = (
    <>
      <H2 className="BigRow-Title">{title}</H2>
      <P className="BigRow-Description">{description}</P>

      {actionComponent && actionComponent}
    </>
  )

  return (
    <li className={classNames('BigRow', { BigRow__withLink: link, BigRow__noLink: !link }, className)}>
      {link ? (
        <Link className="BigRow-Link" to={link}>
          {bigRowContent}
        </Link>
      ) : (
        bigRowContent
      )}
    </li>
  )
}

export default BigRow
