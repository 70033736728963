import { Account } from '../../types'
import endpoints from '../../utilities/endpoints'
import { client } from '../client'

type CreateLinkedAccounts = (accounts: Account[]) => Promise<Account[]>

export const createLinkedAccounts: CreateLinkedAccounts = async (accounts) => {
  const response = await client.post<Account[]>(endpoints.plaidAccounts, accounts)

  return response.data
}
