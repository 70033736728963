import classNames from 'classnames'

import { ReactComponent as CloseIcon } from '../../assets/img/close.svg'

const CloseButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, className, ...rest }) => {
  return (
    <button type="button" className={classNames('CloseButton', className)} {...rest}>
      <CloseIcon />
    </button>
  )
}

export default CloseButton
