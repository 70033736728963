import { ReactComponent as AddIcon } from '../assets/img/add.svg'
import { ReactComponent as AdvisorIcon } from '../assets/img/advisor-connect.svg'
import { ReactComponent as AlertIcon } from '../assets/img/alert.svg'
import { ReactComponent as CheckIcon } from '../assets/img/check-circle.svg'
import { ReactComponent as CircleIcon } from '../assets/img/circle.svg'
import { ReactComponent as EnvelopeIcon } from '../assets/img/envelope.svg'
import { ReactComponent as HomeIcon } from '../assets/img/home.svg'
import { ReactComponent as LinkIcon } from '../assets/img/link.svg'
import { ReactComponent as LogoutIcon } from '../assets/img/logout.svg'
import { ReactComponent as SavingIcon } from '../assets/img/saving.svg'
import { ReactComponent as SettingsIcon } from '../assets/img/settings.svg'
import { ReactComponent as TestKitchenIcon } from '../assets/img/test-kitchen.svg'

export const icons = {
  add: AddIcon,
  alert: AlertIcon,
  advisor: AdvisorIcon,
  checked: CheckIcon,
  envelope: EnvelopeIcon,
  home: HomeIcon,
  link: LinkIcon,
  logout: LogoutIcon,
  saving: SavingIcon,
  settings: SettingsIcon,
  testKitchen: TestKitchenIcon,
  generic: CircleIcon
}
