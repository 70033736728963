import ContentHeader from '../../components/ContentHeader'
import PageContent from '../../components/PageContent'
import { PageNames, PageUrls } from '../../components/Router/routes'
import WealthManagerInfo from '../../components/WealthManagerInfo'
import withAuthentication from '../../hoc/withAuthentication'

const AdvisorConnect: React.FC = () => {
  return (
    <>
      <ContentHeader headingOne={PageNames.advisorConnect} back={PageUrls.accountCategories()} />
      <PageContent>
        <WealthManagerInfo />
      </PageContent>
    </>
  )
}

export default withAuthentication(AdvisorConnect)
