type Props = {
  onClick: () => void
}

const NavMask: React.FC<Props> = ({ onClick: closeNav }) => {
  return (
    <button className="NavMask" onClick={closeNav} type="button">
      Hidden button that closes the mobile navigation
    </button>
  )
}

export default NavMask
