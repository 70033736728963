import { AxiosError } from 'axios'
import { NavigateFunction } from 'react-router'

import { createSession } from '../../api/createSession'
import { PageUrls } from '../../components/Router/routes'
import { FormValues } from '../../forms/LoginForm'
import { Storage } from '../../utilities/storage'

export const invalidLoginErrorLanguage = 'Invalid credentials. Try again.'

export const login = async (
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
  navigate: NavigateFunction,
  formValues: FormValues
): Promise<void> => {
  try {
    const response = await createSession(formValues)
    Storage.setAuthToken(response.auth_token)
    const redirectPath = Storage.getRedirectPath() || PageUrls.accountCategories()

    Storage.deleteRedirectPath()
    navigate(redirectPath)
  } catch (error) {
    const axiosError = error as AxiosError
    const message = axiosError.message || invalidLoginErrorLanguage

    switch (axiosError.response?.status) {
      case 404:
        setError(invalidLoginErrorLanguage)
        break
      default:
        setError(message as string)
        break
    }
  }
}
