import classNames from 'classnames'
import { HTMLProps } from 'react'

type Props = HTMLProps<HTMLLabelElement> & {
  labeltext: string
}

const FieldLabel: React.FC<Props> = ({ children, className, htmlFor, labeltext, ...rest }) => (
  <label className={classNames('FieldLabel', className)} htmlFor={htmlFor} {...rest}>
    {labeltext}
    {children}
  </label>
)

export default FieldLabel
