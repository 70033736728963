import classNames from 'classnames'

import { ReactComponent as LoaderSVG } from '../../assets/img/loader.svg'

const Loading: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className, ...rest }) => {
  return (
    <div role="progressbar" className={classNames('Loading', className)} {...rest}>
      <LoaderSVG />
    </div>
  )
}

export default Loading
