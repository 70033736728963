import { FormikProps, withFormik } from 'formik'
import * as Yup from 'yup'

import Button from '../../components/Button'
import Form from '../../components/Form'
import TextFieldWithLabel from '../components/TextFieldWithLabel'

const validationSchema = Yup.object({
  email: Yup.string().trim().email().required('Required'),
  password: Yup.string().trim().required('Required')
})

export interface FormValues {
  email: string
  password: string
}

interface Props {
  onSubmit: (values: FormValues) => Promise<void>
}

const LoginForm: React.FC<Props & FormikProps<FormValues>> = (props) => {
  return (
    <div className="LoginForm-Wrapper">
      <Form className="LoginForm">
        <TextFieldWithLabel element="LoginForm" fieldName="email" labelText="Email Address" />

        <TextFieldWithLabel element="LoginForm" fieldName="password" labelText="Password" type="password" />

        <Button
          className="Form-Button Form-Button__toggleContrast"
          disabled={!props.isValid || props.isSubmitting}
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  )
}

export default withFormik<Props, FormValues>({
  displayName: 'LoginForm',
  handleSubmit: (values, { props }) => props.onSubmit(values),
  mapPropsToValues: () => ({ email: '', password: '' }),
  validateOnMount: true,
  validationSchema
})(LoginForm)
