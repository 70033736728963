import { NavLink, NavLinkProps } from 'react-router-dom'

import { navLinkClass } from './helpers'

type Props = {
  className?: string
  to: NavLinkProps['to']
}

const NavigationLink: React.FC<Props> = ({ children, className, to }) => {
  return (
    <NavLink to={to} className={({ isActive }) => navLinkClass(isActive, className)}>
      {children}
    </NavLink>
  )
}

export default NavigationLink
