import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useNavigate } from 'react-router'

import { createAccessToken } from '../../api/createAccessToken'
import { createLinkToken } from '../../api/createLinkToken'
import { getPlaidAccounts } from '../../api/getPlaidAccounts'
import AccountList from '../../components/AccountList'
import AddAccountRowLinks from '../../components/AddAccountRowLinks'
import Button from '../../components/Button'
import ContentHeader from '../../components/ContentHeader'
import { PageNames, PageUrls } from '../../components/Router/routes'
import { useAppContext } from '../../contexts/AppContext'
import { Account, SaveStates } from '../../types'
import { addLinkedAccounts } from './helpers'

const plaidAccounts = (plaidAccounts: Account[] | null): Account[] => (plaidAccounts?.length ? plaidAccounts : [])
const plaidAccountsLength = (plaidAccounts: Account[] | null) => plaidAccounts?.length || 0

const NewLinkedAccount: React.FC = () => {
  const [appState] = useAppContext()
  const [error, setError] = useState<string | undefined>(undefined)
  const [formDrawerOpen, setFormDrawerOpen] = useState<boolean>(false)
  const [plaidLinkToken, setPlaidLinkToken] = useState<string>('')
  const [saveState, setSaveState] = useState<SaveStates>('static')
  const [rowLinksDisabled, setRowLinksDisabled] = useState<boolean>(true)
  const [plaidApiAccounts, setPlaidApiAccounts] = useState<Account[]>([])

  const navigate = useNavigate()

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: plaidLinkToken,
    onSuccess: async (public_token) => {
      const accessTokenResponse = await createAccessToken({ public_token })
      const plaidApiAccounts = await getPlaidAccounts(accessTokenResponse.access_token)

      setPlaidApiAccounts(plaidApiAccounts)
    },
    onExit: () => setRowLinksDisabled(false),
    onEvent: (event) => event === 'OPEN' && setRowLinksDisabled(true)
  }

  const numberOfAccounts = plaidAccountsLength(plaidApiAccounts)
  const hasAccounts = numberOfAccounts > 0
  const plaidAccountsList = plaidAccounts(plaidApiAccounts)

  const { open, ready } = usePlaidLink(config)

  const onCancel = () => {
    navigate(PageUrls.addAccountInterstitial())
  }

  const saveLinkedAccounts = (values: Account[]) => {
    addLinkedAccounts(values, setError, setSaveState)
  }

  useEffect(() => {
    saveState === 'saved' && navigate(PageUrls.accountCategories())
  }, [navigate, saveState])

  useEffect(() => {
    createLinkToken().then((data) => setPlaidLinkToken(data.link_token))
  }, [])

  useEffect(() => {
    if (ready) {
      open()
    }
  }, [open, ready, setRowLinksDisabled])

  useEffect(() => {
    hasAccounts && setFormDrawerOpen(true)
  }, [hasAccounts])

  return (
    <>
      <AddAccountRowLinks disabled={rowLinksDisabled} openPlaid={open} />
      <div
        className={classNames('ImportedAccountsWrapper', {
          ImportedAccountsWrapper__drawerOpen: formDrawerOpen,
          ImportedAccountsWrapper__loading: appState.global.loading,
          ImportedAccountsWrapper__noAccounts: !hasAccounts
        })}
      >
        <ContentHeader
          back={PageUrls.accountCategories()}
          className="ContentHeader__ImportedAccountsWrapper"
          close={true}
          headingOne={PageNames.addNewAccount}
          setFormDrawerOpen={setFormDrawerOpen}
        />

        <div className="ImportedAccountsWrapper-ContentPane">
          {hasAccounts ? (
            <>
              <h3 className="ImportedAccountsWrapper-h3">
                {numberOfAccounts} {numberOfAccounts === 1 ? 'account' : 'accounts'} found
              </h3>
              <AccountList accounts={plaidAccountsList} saveState={saveState} />

              <Button type="button" variant="fullWidth" onClick={() => saveLinkedAccounts(plaidAccountsList)}>
                Confirm
              </Button>

              <Button type="button" variant="link" onClick={onCancel}>
                Cancel
              </Button>
              {error && <div className="ImportedAccountsWrapper-Error">{error}</div>}
            </>
          ) : (
            <AddAccountRowLinks disabled={rowLinksDisabled} openPlaid={open} />
          )}
        </div>
      </div>
    </>
  )
}

export default NewLinkedAccount
