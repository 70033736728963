import classNames from 'classnames'

import { Account, SaveStates } from '../../types'
import { Currency } from '../../utilities/Currency'
import { icons } from '../../utilities/icons'
import BigRow from '../BigRow'
import P from '../Paragraph'
import { accountLink } from './helpers'

export interface Props {
  className?: string
  account: Account
  saveState?: SaveStates
}

const AccountRow: React.FC<Props> = (props) => {
  const {
    account: { account_category_id, id, institution_name, sub_category, balance_in_cents, plaid_account_id },
    saveState
  } = props

  const actionComponent = (
    <div className="BigRow__action AccountRow-Action">
      <P className="AccountRow-P AccountRow__BalanceInCents">{Currency.centsToDollars(balance_in_cents)}</P>
      <div className="BigRow-LinkedMeta">
        {saveState === 'saving' && <icons.saving title="Saving account" />}
        {saveState === 'saved' && <icons.checked title="Account saved" />}
        {plaid_account_id && <icons.link title="Linked Account" />}
      </div>
    </div>
  )

  return (
    <BigRow
      className={classNames('AccountRow', props.className, { AccountRow__linked: plaid_account_id })}
      actionComponent={actionComponent}
      title={institution_name}
      description={sub_category || ''}
      link={accountLink(account_category_id, id)}
    />
  )
}

export default AccountRow
