import classNames from 'classnames'

import { ReactComponent as AddIconSVG } from '../../assets/img/add.svg'

type Props = {
  className?: string
  text?: string
}

const AddIcon: React.FC<Props> = ({ className, text }) => {
  return (
    <span className={classNames('AddIcon', className)} role="img">
      <AddIconSVG />
      {text && <span className="AddIcon-Text">{text}</span>}
    </span>
  )
}

export default AddIcon
